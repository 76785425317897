export const VOTE_OPTIONS = [
  {
    title: 'Support',
    value: 1,
  },
  {
    title: 'Abstain',
    value: 2,
  },

  {
    title: 'Reject',
    value: 3,
  },
  {
    title: 'Veto',
    value: 4,
  },
]
